import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { openCloseSurvey } from "../slices/modalSlice";
import styled from "styled-components/macro";

import SpaceshipHallway from "../assets/images/backgroundImages/spaceshipWhite.jpeg";

import { CloseOutline } from "@styled-icons/evaicons-outline";

import colors from "../constants/colors";
import fontSize from "../constants/fontSize";
import breakpoints from "../constants/breakpoints";
import SurveyForm from "./forms/SurveyForm";
import useWindowSize from "../hooks/useWindowSize";

const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  opacity: ${(props) => (props.open ? "1" : "0")};
  height: ${(props) => `${props.height}px`};
  width: 100vw;
  padding: 2rem;
  background-color: ${colors.overlay};
  transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;

  @media screen and (max-width: ${breakpoints.lg}) {
    padding: 0;
  }
`;

const BackgroundWrap = styled.div`
  width: 100%;
  height: 100%;
  transform: scale(${(props) => (props.open ? "1" : "0")});
  transition: transform 0.3s ease-in-out;
`;

const ContentWrap = styled.div`
  width: 100%;
  height: 100%;
  background: url(${SpaceshipHallway});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: auto;
`;

const ModalContent = styled.div`
  width: 100%;
  min-height: 100%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media screen and (max-width: ${breakpoints.md}) {
    padding: 2rem;
  }
`;

const StyledCloseIcon = styled(CloseOutline)`
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 999;

  @media screen and (min-width: ${breakpoints.lg}) {
    top: 2rem;
    left: 2rem;
  }
`;

const ModalBody = styled.div`
  height: 100%;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 25px;
  background-color: ${colors.modalBackground};
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    padding: 1rem;
  }
`;

const ModalHeader = styled.div`
  color: ${colors.white};
  font-size: ${fontSize.twoRem};
  text-align: center;
  font-weight: 600;

  @media screen and (max-width: ${breakpoints.md}) {
    font-size: ${fontSize.oneHalfRem};
  }
`;

const SurveyFormModal = () => {
  const isSurveyOpen = useSelector((state) => state.modal.isSurveyOpen);
  const size = useWindowSize();
  const dispatch = useDispatch();

  return (
    <ModalWrapper open={isSurveyOpen} height={size.height}>
      <BackgroundWrap open={isSurveyOpen}>
        <StyledCloseIcon
          onClick={() => dispatch(openCloseSurvey(false))}
          size="26px"
        />
        <ContentWrap>
          <ModalContent>
            <ModalBody>
              <ModalHeader>SURVEY & FEEDBACK</ModalHeader>
              <SurveyForm />
            </ModalBody>
          </ModalContent>
        </ContentWrap>
      </BackgroundWrap>
    </ModalWrapper>
  );
};

export default SurveyFormModal;
