import React from "react";
import styled from "styled-components/macro";
import colors from "../../constants/colors";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.img`
  width: 40%;
  margin-top: -15%;
  margin-bottom: -15%;
`;

const Title = styled.div`
  text-transform: uppercase;
  font-size: calc(1.8vw);
  margin: 3% 0;
  font-family: "Calibri-Bold";
  font-weight: 600;
  color: ${colors.white};
  letter-spacing: 0.2%;
  text-align: center;
`;

const Content = styled.div`
  width: 100%;
  border-radius: 15px;
  background-color: ${colors.overviewBackground};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding: 1.5%;
`;

const QuoteText = styled.div`
  max-width: 700px;
  text-transform: uppercase;
  font-size: calc(1.8vw);
  font-family: "Calibri-Bold";
  font-weight: 600;
  color: ${colors.white};
  letter-spacing: 2px;
  text-align: center;
  margin-top: 4%;
`;

const BlueBox = styled.div`
  width: 17%;
  height: calc(13vw);
  margin: calc(1.5vw);
  padding: calc(0.5vw);
  border-radius: 15px;
  background-color: ${colors.blueBox};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

const BoxTitle = styled.div`
  color: ${colors.white};
  font-family: "Calibri-Bold";
  font-size: calc(1.6vw);
  text-align: center;
`;

const BoxText = styled.div`
  color: ${colors.white};
  font-family: "Calibri-Bold";
  font-size: calc(1.3vw);
  text-align: center;
`;

const ImageBox = styled.div`
  width: 15%;
  height: calc(13vw);
  margin: 1%;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 15px;
`;

const CompanyOverview = (props) => {
  const { logo, whatTheyDo, imageOne, imageTwo, founded, valuation, quote } =
    props;
  return (
    <Wrapper>
      <Logo src={logo} alt="Logo" />
      <Title>Company Overview</Title>
      <Content>
        <BlueBox>
          <BoxTitle>WHAT THEY DO</BoxTitle>
          <BoxText>{whatTheyDo}</BoxText>
        </BlueBox>
        <ImageBox image={imageOne} />
        <BlueBox>
          <BoxTitle>FOUNDED IN</BoxTitle>
          <BoxText>{founded}</BoxText>
        </BlueBox>
        <ImageBox image={imageTwo} />
        <BlueBox>
          <BoxTitle>LATEST VALUATION</BoxTitle>
          <BoxText>${valuation}B</BoxText>
        </BlueBox>
      </Content>
      <QuoteText>{`"${quote}"`}</QuoteText>
    </Wrapper>
  );
};

export default CompanyOverview;
