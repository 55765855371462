import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import CompanyPageLayout from "../components/CompanyPageLayout";
import SideNavigation from "../components/SideNavigation";
import CompanyTabs from "../components/companyPage/CompanyTabs";
import bgImg from "../assets/images/companyPages/room1.jpg";
import Amplitude from "../services/amplitudeHelper";
import { useParams } from "react-router";
import Loader from "../assets/images/icons/Spinner-1s-200px.svg";
import { getCompanyData } from "../services/axios/cms";

const LoaderWrap = styled.div`
  height: 90%;
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 3% 10% 8% 4%;
`;

const StyledLoader = styled.img`
  width: calc(10vw);
`;

const SFCompanyPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("video");
  const { company } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchCompanyData = async () => {
      const response = await getCompanyData(company);

      if (response?.isSuccess) {
        setData(response?.data);
        setIsLoading(false);
      }
    };
    Amplitude.visit(company);
    fetchCompanyData();
  }, [company]);

  return (
    <CompanyPageLayout backgroundImage={bgImg}>
      <SideNavigation activeTab={activeTab} setActiveTab={setActiveTab} />
      {isLoading ? (
        <LoaderWrap>
          <StyledLoader src={Loader} />
        </LoaderWrap>
      ) : (
        <CompanyTabs activeTab={activeTab} data={data} />
      )}
    </CompanyPageLayout>
  );
};

export default SFCompanyPage;
