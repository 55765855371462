import React, { useEffect, useState, useRef } from "react";
import { isMobile, isSafari } from "react-device-detect";
import { motion, useAnimation } from "framer-motion";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import SixteenByNineContainer from "./SixteenByNineContainer";
import BackgroundImage from "./BackgroundImage";
import Left from "../assets/images/leftDoor.png";
import Right from "../assets/images/rightDoor.png";
import GalleryNavigation from "./GalleryNavigation";
import DoorSound from "../assets/audio/doorSfx.mp3";

const LeftDoor = styled(motion.img)`
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1000000;
`;

const RightDoor = styled(motion.img)`
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1000000;
`;

const GalleryLayout = ({ backgroundImage, children }) => {
  const [showTransition, setShowTransition] = useState(false);
  const audioRef = useRef(null);
  const history = useHistory();
  const leftControls = useAnimation();
  const rightControls = useAnimation();

  const handleAudioStart = () => {
    leftControls.start({
      left: "-60%",
    });
    rightControls.start({
      right: "-60%",
    });
  };

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.state &&
      history.location.state.from &&
      history.location.state.from === "/"
    ) {
      setShowTransition(true);
      window.history.replaceState({ from: null }, "");
    }
  }, [history]);

  useEffect(() => {
    if (showTransition && audioRef && audioRef.current) {
      if (isMobile && isSafari) {
        handleAudioStart();
        setTimeout(() => {
          setShowTransition(false);
        }, 2200);
      } else {
        audioRef.current.play();
      }
    }
    // eslint-disable-next-line
  }, [audioRef, showTransition]);

  return (
    <SixteenByNineContainer>
      <BackgroundImage image={backgroundImage} />
      {showTransition && (
        <>
          <audio
            ref={audioRef}
            src={DoorSound}
            onPlay={() => handleAudioStart()}
            onEnded={() => setShowTransition(false)}
            preload="metadata"
            playsInline
          />
          <LeftDoor
            src={Left}
            initial={{ left: 0 }}
            animate={leftControls}
            transition={{ duration: 1.5, type: "tween", delay: 0.5 }}
          />
          <RightDoor
            src={Right}
            initial={{ right: 0 }}
            animate={rightControls}
            transition={{ duration: 1.5, type: "tween", delay: 0.5 }}
          />
        </>
      )}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        <GalleryNavigation />
        {children}
      </motion.div>
    </SixteenByNineContainer>
  );
};

export default GalleryLayout;
