import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components/macro";
import useWindowSize from "../hooks/useWindowSize";
import MuteButton from "./MuteButton";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${(props) => `${props.height}px`};
  display: flex;
  align-items: center;
`;

const Container = styled(motion.div)`
  width: 100vw;
  position: relative;
  overflow: auto;
`;

const AspectRatio = styled.div`
  width: 100%;
  padding-bottom: 56.25%;
`;

const SixteenByNineContainer = ({ children, hideMute }) => {
  const size = useWindowSize();

  return (
    <Wrapper height={size.height}>
      <Container
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ type: "tween", duration: 0.5 }}
      >
        <AspectRatio />
        {children}
        {!hideMute && <MuteButton />}
      </Container>
    </Wrapper>
  );
};

export default SixteenByNineContainer;
