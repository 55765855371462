const fontSize = {
  // 32px
  twoRem: "2rem",
  // 24px
  oneHalfRem: "1.5rem",
  // 19.2px
  xxLarge: "1.2rem",
  // 16px
  xLarge: "1rem",
  // 14px
  large: "0.875rem",
  // 11px
  regular: "0.6875rem",
  // 10px
  small: "0.625rem",
};

export default fontSize;
