import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components/macro";
import GalleryHome from "../assets/images/lobbyHome.png";
import Border from "../assets/images/navBorder.png";
import colors from "../constants/colors";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const NavWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 13%;
  background-color: ${colors.navigation};
  backdrop-filter: blur(8px);
`;

const NavContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const GalleryHomeButton = styled(motion.img)`
  position: absolute;
  right: 3%;
  top: 50%;
  width: 15%;
  cursor: pointer;
`;

const NavBorder = styled(motion.img)`
  box-sizing: border-box;
  position: absolute;
  bottom: 15%;
  left: 3%;
  width: 95%;
`;

const GalleryNavigation = () => {
  const history = useHistory();

  return (
    <NavWrap>
      <NavContent>
        <Link to="/">
          <GalleryHomeButton
            src={GalleryHome}
            onClick={() => history.push("/")}
            initial={{ scale: 1, translateY: "-50%" }}
            transition={{ type: "tween" }}
            whileHover={{ scale: 1.1, translateY: "-50%" }}
          />
        </Link>
        <NavBorder src={Border} />
      </NavContent>
    </NavWrap>
  );
};

export default GalleryNavigation;
