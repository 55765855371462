import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { motion } from "framer-motion";
import useWindowSize from "../hooks/useWindowSize";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import MuteButton from "./MuteButton";

const FullIFrame = styled(motion.iframe)`
  height: ${(props) => `${props.hide ? 0 : props.height}px`};
  width: ${(props) => (props.hide ? "0vw" : "100vw")};
  border: 0;
  display: ${(props) => (props.hide ? "none" : "block")};
  z-index: ${(props) => props.hide && -9999};
`;

const Lobby = (props) => {
  const size = useWindowSize();
  const hasClickedEnter = useSelector((state) => state.app.hasClickedEnter);
  const muteEnabled = useSelector((state) => state.app.muteEnabled);

  const pauseTour = () => {
    setTimeout(() => {
      window.tour.pause();
    }, 2000);
  };

  const resumeTour = () => {
    window.tour.resume();
  };

  useEffect(() => {
    if (window && window.tour) {
      props.location.pathname === "/" ? resumeTour() : pauseTour();
    }
  });

  const variants = {
    hide: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };

  return (
    <>
      <FullIFrame
        src="lobby/index.htm"
        variants={variants}
        animate={
          !hasClickedEnter || props.location.pathname !== "/"
            ? "hide"
            : "visible"
        }
        transition={{ delay: 0.3 }}
        hide={!hasClickedEnter || props.location.pathname !== "/"}
        height={size.height}
      />
      {props.location.pathname === "/" && muteEnabled && <MuteButton />}
    </>
  );
};

export default withRouter(Lobby);
