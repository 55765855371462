import React from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { userStopAudio, userPlayAudio } from "../slices/backgroundAudioSlice";
import { MdVolumeUp, MdVolumeOff } from "react-icons/md";
import colors from "../constants/colors";

const StyledMute = styled(MdVolumeOff)``;

const StyledUnmute = styled(MdVolumeUp)``;

const IconWrap = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  border-radius: 25px;
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  z-index: 999999;
  cursor: pointer;
`;

const MuteButton = () => {
  const isPlaying = useSelector((state) => state.backgroundAudio.isPlaying);
  const dispatch = useDispatch();

  const handleMute = () => {
    dispatch(userStopAudio());
  };

  const handleUnmute = () => {
    dispatch(userPlayAudio());
  };

  return (
    <>
      {isPlaying ? (
        <IconWrap>
          <StyledUnmute onClick={handleMute} />
        </IconWrap>
      ) : (
        <IconWrap>
          <StyledMute onClick={handleUnmute} />
        </IconWrap>
      )}
    </>
  );
};

export default MuteButton;
