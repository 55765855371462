import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { isMobile, isTablet } from "react-device-detect";
import breakpoints from "../constants/breakpoints";
import { setIsMobile, setIsTablet, setIsDesktop } from "../slices/layoutSlice";
import fontSize from "../constants/fontSize";
import colors from "../constants/colors";
import RotateDevice from "../assets/images/icons/rotateDevice.png";
import useWindowSize from "../hooks/useWindowSize";

const FullSizeWrap = styled.div`
  width: 100vw;
  height: ${(props) => `${props.height}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ContentWrap = styled.div`
  height: ${(props) => `${props.height}px`};
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 1000000;
`;

const RealView = styled.div`
  height: ${(props) => `${props.height}px`};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const RotatePhone = styled(motion.img)`
  margin-bottom: 3rem;
`;

const Message = styled.div`
  font-size: ${fontSize.xLarge};
  color: ${colors.white};
  text-align: center;
  font-family: "Calibri-Bold";
  font-weight: 600;
`;

const OrientationHandler = ({ children }) => {
  const [isLandscape, setIsLandscape] = useState(false);
  const dispatch = useDispatch();
  const size = useWindowSize();

  const handleWindowResize = () => {
    if (window.innerWidth <= Number(breakpoints.sm.split("px")[0])) {
      dispatch(setIsMobile(true));
      dispatch(setIsTablet(false));
      dispatch(setIsDesktop(false));
    } else if (
      window.innerWidth > Number(breakpoints.sm.split("px")[0]) &&
      window.innerWidth <= Number(breakpoints.lg.split("px")[0])
    ) {
      dispatch(setIsMobile(false));
      dispatch(setIsTablet(true));
      dispatch(setIsDesktop(false));
    } else {
      dispatch(setIsMobile(false));
      dispatch(setIsTablet(false));
      dispatch(setIsDesktop(true));
    }
  };

  const handleOrientation = () => {
    switch (window.orientation) {
      case -90:
        setIsLandscape(true);
        break;
      case 90:
        setIsLandscape(true);
        break;
      default:
        setIsLandscape(false);
        break;
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    handleWindowResize();

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener("orientationchange", handleOrientation);
    handleOrientation();

    return () => {
      window.removeEventListener("orientationchange", handleOrientation);
    };
  }, []);

  return (
    <>
      <FullSizeWrap height={size.height}>
        {!isLandscape && (isTablet || isMobile) && (
          <ContentWrap height={size.height}>
            <RotatePhone
              src={RotateDevice}
              alt="Rotate Phone"
              initial={{ rotate: 0 }}
              animate={{ rotate: 45 }}
              transition={{
                duration: 1,
                repeat: Infinity,
                repeatType: "reverse",
                repeatDelay: 0.5,
              }}
            />
            <Message>
              Please rotate your device or resize your browser to enjoy this
              experience!
            </Message>
          </ContentWrap>
        )}
        <RealView height={size.height}>{children}</RealView>
      </FullSizeWrap>
    </>
  );
};

export default OrientationHandler;
