import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    videoPlayed: false,
    firstEntry: true,
    hasClickedEnter: false,
    muteEnabled: false,
    siteData: {},
  },
  reducers: {
    setVideoPlayed: (state) => {
      state.videoPlayed = true;
    },
    setFirstEntry: (state) => {
      state.firstEntry = false;
    },
    setHasClickedEnter: (state, action) => {
      state.hasClickedEnter = action.payload;
    },
    setMuteEnabled: (state) => {
      state.muteEnabled = true;
    },
    setSiteData: (state, action) => {
      state.siteData = action.payload;
    },
  },
});

export const {
  setVideoPlayed,
  setFirstEntry,
  setHasClickedEnter,
  setMuteEnabled,
  setSiteData,
} = appSlice.actions;

export default appSlice.reducer;
