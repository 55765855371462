const colors = {
  white: "white",
  modalBackground: "rgba(0,0,0,0.8)",
  overlay: "rgba(0,0,0,0.3)",
  grey: "#d3d3d3",
  midGrey: "#858282",
  darkGrey: "#5e5e5e",
  btnGrey: "#707070",
  navigation: "rgba(0,0,0,0.7)",
  sliderHover: "rgb(0, 226, 255)",
  textBlue: "rgba(0, 226, 255, 1)",
  textShadowBlue: "rgba(0, 226, 255, 0.3)",
  textRed: "rgb(255, 0, 119)",
  textShadowRed: "rgba(255, 0, 119, 0.3)",
  overviewBackground: "rgba(255,255,255,0.1)",
  highlightBackground: "rgba(4,15,43,0.7)",
  highlightBox: "rgba(0, 119, 178, 0.3)",
  divider: "rgba(255,255,255,0.3)",
  blueBox: "rgba(0,12,48,1)",
  popover: "#0880AE",
  popoverHover: "#040F2B",
  galleryNav: "rgba(34,36,36, 0.3)",
  declarationBox: "rgba(39, 170, 225, 0.3)",
};

export default colors;
