import React from "react";
import styled from "styled-components/macro";
import colors from "../../constants/colors";
import { IoPushOutline } from "react-icons/io5";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 50%;
  margin-top: -15%;
  margin-bottom: -15%;
`;

const Link = styled.a`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

const StyledButton = styled.button`
  border: 0;
  font-size: calc(1vw);
  color: ${colors.white};
  background-color: ${colors.popover};
  margin-top: 2%;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  padding: 1% 2%;
  border-radius: 2.5px;
  position: relative;
  display: flex;
  align-items: center;

  :hover {
    background-color: ${colors.popoverHover};
  }
`;

const StyledPushIcon = styled(IoPushOutline)`
  margin-left: 0.25rem;
`;

const CompanyWebsite = ({ logo, url }) => {
  return (
    <Wrapper>
      <Link href={url} target="_blank" rel="noreferrer noopener">
        <Logo src={logo} alt="Logo" />
        <StyledButton>
          Visit Website <StyledPushIcon />
        </StyledButton>
      </Link>
    </Wrapper>
  );
};

export default CompanyWebsite;
