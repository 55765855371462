import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components/macro";
import Tab from "../assets/images/sideTab.png";
import sideTabs from "../constants/sideTabs";

import colors from "../constants/colors";
import { useParams } from "react-router";
import Amplitude from "../services/amplitudeHelper";

const Container = styled.div`
  position: absolute;
  top: 10%;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 90%;
  width: 20%;
  z-index: 9999;
  padding-top: 2.5%;
`;

const SideTabWrap = styled(motion.div)`
  position: relative;
  cursor: pointer;
  width: 100%;
`;

const SideTab = styled(motion.img)`
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

const SideTabText = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${(props) => (props.active ? colors.textBlue : colors.white)};
  text-align: right;
  position: absolute;
  top: 4%;
  right: 30%;
  font-size: 1.5vw;
  font-family: "Calibri-Z";
  font-weight: 800;
  text-transform: uppercase;
  text-shadow: ${(props) =>
    props.active && `0px 2px 5px ${colors.sliderHover}`};
  cursor: pointer;
  transition: text-shadow 0.3s ease-in-out, color 0.3s ease-in-out;
`;

const SideNavigation = ({ activeTab, setActiveTab }) => {
  const { company } = useParams();

  const handleTabClick = (tabVal) => {
    Amplitude.clickTab(tabVal, company);
    setActiveTab(tabVal);
  };

  const variants = {
    active: { x: "-5%" },
    inActive: { x: "-15%" },
  };

  return (
    <Container>
      {sideTabs.map((tab) => {
        return (
          <SideTabWrap
            key={tab.id}
            whileHover={{ x: 0 }}
            variants={variants}
            animate={activeTab === tab.value ? "active" : "inActive"}
            initial={{ x: "-5%" }}
            transition={{ type: "tween" }}
            onClick={() => handleTabClick(tab.value)}
          >
            <SideTab src={Tab} />
            <SideTabText active={activeTab === tab.value}>
              {tab.label}
            </SideTabText>
          </SideTabWrap>
        );
      })}
    </Container>
  );
};

export default SideNavigation;
