import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openCloseLearnMore } from "../slices/modalSlice";
import { stopAudio, playAudio } from "../slices/backgroundAudioSlice";
import styled from "styled-components/macro";
import { CloseOutline } from "@styled-icons/evaicons-outline";

import Play from "../assets/images/icons/playVideo.png";

import breakpoints from "../constants/breakpoints";
import colors from "../constants/colors";
import Amplitude from "../services/amplitudeHelper";
import useWindowSize from "../hooks/useWindowSize";

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  opacity: ${(props) => (props.open ? "1" : "0")};
  height: ${(props) => `${props.height}px`};
  width: 100vw;
  background-color: ${colors.overlay};
  transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
  padding: 0;
`;

const BackgroundWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  transform: scale(${(props) => (props.open ? 1 : 0)});
  transition: transform 0.3s ease-in-out;
  padding: 0 1rem;
`;

const StyledCloseIcon = styled(CloseOutline)`
  width: calc(3vw);
  height: calc(3vw);
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: ${colors.white};
  z-index: 999;
`;

const VideoContainer = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  margin: 1rem 0;
  margin-right: 1rem;
`;

const VideoPlayer = styled.video`
  width: 100%;
  height: 90%;
  display: block;
  margin: 0 auto;
  background-color: black;
`;

const PlaylistContainer = styled.div`
  width: 20%;
  height: 80%;
  border-radius: 5px;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0.5rem;
  margin-top: 1rem;
`;

const VideoRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 1px solid
    ${(props) => (props.active ? colors.textBlue : "transparent")};
  border-radius: 5px;
  padding: 0.25rem;
  cursor: pointer;
  margin-top: 0.5rem;

  @media screen and (min-width: ${breakpoints.lg}) {
    flex-direction: row;
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  margin-bottom: 0.5rem;

  @media screen and (min-width: ${breakpoints.lg}) {
    width: 40%;
    margin-right: 1rem;
    margin-bottom: 0;
  }
`;

const VideoDetails = styled.div`
  flex: 1;
`;

const VideoTitle = styled.div`
  font-size: 0.6rem;
  word-break: break-word;
  color: ${colors.white};
  text-align: center;

  @media screen and (min-width: ${breakpoints.lg}) {
    font-size: 0.8rem;
    text-align: left;
  }
`;

const PlayButton = styled.img`
  width: 15%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
`;

const IntroVideoModal = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const size = useWindowSize();
  const videoRef = useRef(null);
  const isLearnMoreOpen = useSelector((state) => state.modal.isLearnMoreOpen);
  const dispatch = useDispatch();
  const playlistVideos = useSelector(
    (state) => state?.app?.siteData?.playlist_videos
  );

  useEffect(() => {
    if (activeIndex) {
      startVideo();
    }
    // eslint-disable-next-line
  }, [activeIndex]);

  const handleClose = () => {
    setIsVideoPlaying(false);
    videoRef.current.pause();
    dispatch(openCloseLearnMore(false));
  };

  const handleSelectVideo = (index) => {
    if (activeIndex !== index) {
      setActiveIndex(index);
    }
  };

  const startVideo = () => {
    Amplitude.clickWatchVideo("Home", "Learn More");
    setIsVideoPlaying(true);
    dispatch(stopAudio());
    videoRef.current.play();
  };

  const videoEnded = () => {
    dispatch(playAudio());
    setIsVideoPlaying(false);
  };

  const videoPlay = () => {
    dispatch(stopAudio());
  };

  const videoPaused = () => {
    dispatch(playAudio());
  };

  return (
    <Overlay open={isLearnMoreOpen} height={size.height}>
      <StyledCloseIcon onClick={() => handleClose()} size="24px" />
      <BackgroundWrap open={isLearnMoreOpen}>
        <VideoContainer>
          {!isVideoPlaying && <PlayButton src={Play} onClick={startVideo} />}
          <VideoPlayer
            ref={videoRef}
            src={playlistVideos[activeIndex]?.url}
            type="video/mp4"
            poster={playlistVideos[activeIndex].thumbnail_url}
            controls={isVideoPlaying}
            onPause={videoPaused}
            onEnded={videoEnded}
            onPlay={videoPlay}
          />
        </VideoContainer>
        <PlaylistContainer>
          {playlistVideos?.map((video, index) => {
            return (
              <VideoRow
                onClick={() => handleSelectVideo(index)}
                active={activeIndex === index}
              >
                <Thumbnail src={video?.thumbnail_url} alt="Thumbnail" />
                <VideoDetails>
                  <VideoTitle>{video?.title}</VideoTitle>
                </VideoDetails>
              </VideoRow>
            );
          })}
        </PlaylistContainer>
      </BackgroundWrap>
    </Overlay>
  );
};

export default IntroVideoModal;
