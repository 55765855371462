import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { playAudio } from "../slices/backgroundAudioSlice";
import { openCloseLearnMore, openCloseSurvey } from "../slices/modalSlice";
import { setMuteEnabled } from "../slices/appSlice";
import {
  setLearnMorePlaying,
  setSfxPlaying,
  setSurveyPlaying,
} from "../slices/voiceoverSlice";
import Amplitude from "./amplitudeHelper";

const PostMessageHandler = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const siteData = useSelector((state) => state.app.siteData);

  useEffect(() => {
    window.addEventListener("message", (event) => {
      switch (event.data) {
        case "Survey":
          Amplitude.clickModal("Survey");
          dispatch(openCloseSurvey(true));
          break;
        case "Space Frontier":
          Amplitude.clickDoor("Space Frontier");
          history.push("/space-frontier", { from: "/" });
          break;
        case "Metaverse":
          Amplitude.clickDoor("Metaverse");
          history.push("/metaverse", { from: "/" });
          break;
        case "Digital Zone":
          Amplitude.clickDoor("Digital Zone");
          history.push("/digital-zone", { from: "/" });
          break;
        case "AI Cybersecurity":
          Amplitude.clickDoor("AI Cybersecurity");
          history.push("/ai-cybersecurity", { from: "/" });
          break;
        case "Video":
          Amplitude.clickModal("Learn More");
          dispatch(openCloseLearnMore(true));
          break;
        case "tourLoaded":
          dispatch(setMuteEnabled());
          dispatch(playAudio());
          break;
        case "SurveyVO":
          dispatch(setSurveyPlaying(true));
          break;
        case "VideoVO":
          dispatch(setLearnMorePlaying(true));
          break;
        case "sfx":
          dispatch(setSfxPlaying(true));
          break;
        case "Exit":
          Amplitude.clickExit();
          const redirect = sessionStorage.getItem("redirect");

          window.location.href = redirect
            ? `https://${redirect}`
            : siteData?.redirect_url;
          break;
        default:
          break;
      }
    });
  }, [dispatch, history, siteData?.redirect_url]);

  return <>{children}</>;
};

export default PostMessageHandler;
