const cacheImages = (srcArray) => {
  const promises = srcArray.map(
    (src) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src.default;
        img.onload = () => {
          resolve(src);
        };
        img.onerror = reject;
      })
  );

  return Promise.all(promises);
};

const getAllImages = () => {
  const mainImages = require.context(
    "../assets/images",
    true,
    /\.(jpeg|jpg|png)$/
  );

  const backgroundImages = require.context(
    "../assets/images/backgroundImages",
    true,
    /\.(jpeg|jpg)$/
  );

  const companyBackgroundImages = require.context(
    "../assets/images/companyPages",
    true,
    /\.(jpeg|jpg)$/
  );

  const galleryLogos = require.context(
    "../assets/images/galleryLogos",
    true,
    /\.(jpeg|jpg)$/
  );

  const galleryBackgroundImages = require.context(
    "../assets/images/galleryPages",
    true,
    /\.(jpeg|jpg)$/
  );

  const companyLogos = require.context(
    "../assets/images/logos",
    true,
    /\.(jpeg|jpg|png)$/
  );

  const files = [];

  files.push(mainImages);
  files.push(backgroundImages);
  files.push(companyBackgroundImages);
  files.push(galleryLogos);
  files.push(galleryBackgroundImages);
  files.push(companyLogos);

  const images = [];

  files.forEach((context) => {
    context.keys().forEach((fileName) => {
      images.push(context(fileName));
    });
  });

  return images;
};

export { getAllImages, cacheImages };
