import React, { useEffect } from "react";
import { useHistory } from "react-router";

const EscKeyHandler = ({ children }) => {
  const history = useHistory();

  useEffect(() => {
    window.addEventListener("keyup", (event) => {
      if (event.key === "Escape") {
        if (history.location.pathname === "/") {
          const redirect = localStorage.getItem("redirect");

          window.location.href = redirect
            ? `https://${redirect}`
            : "https://www.ericsenzcapital.com/ericsenzfrontier";
        } else {
          history.goBack();
        }
      }
    });
  }, [history]);

  return <>{children}</>;
};

export default EscKeyHandler;
