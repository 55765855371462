import amplitude from "amplitude-js";

const Amplitude = {
  init: () => {
    amplitude.getInstance().init(`${process.env.REACT_APP_AMPLITUDE_API_KEY}`);
  },
  setUser: (email) => {
    const identify = new amplitude.Identify().set("email", email);
    amplitude.getInstance().identify(identify);
  },
  // TODO: Put all amplitude tracking functions here
  visit: (page) => {
    amplitude.getInstance().logEvent(`Visit ${page}`);
  },

  clickModal: (modal) => {
    amplitude.getInstance().logEvent(`Click ${modal} Modal`);
  },

  clickDoor: (doorName) => {
    amplitude.getInstance().logEvent("Click Door", {
      doorName: doorName,
    });
  },

  clickCompanyLink: (company) => {
    amplitude.getInstance().logEvent("Click Company Link", {
      company: company,
    });
  },

  clickPlaylistVideo: (company, url) => {
    amplitude.getInstance().logEvent("Click Playlist Video", {
      company: company,
      url: url,
    });
  },

  clickWatchVideo: (page, video) => {
    amplitude.getInstance().logEvent("Click Watch Video", {
      page: page,
      video: video,
    });
  },

  clickTab: (tab, company) => {
    amplitude.getInstance().logEvent("Click Tab", {
      tab: tab,
      company: company,
    });
  },

  clickExit: () => {
    amplitude.getInstance().logEvent(`Click Exit`);
  },

  clickMute: () => {
    amplitude.getInstance().logEvent("Click Mute button");
  },

  clickLogout: () => {
    amplitude.getInstance().logEvent("Click Logout");
  },

  submitSurvey: (rating, interests, message) => {
    amplitude.getInstance().logEvent("Submit Survey", {
      rating: rating,
      interests: interests,
      message: message,
    });
  },
};

export default Amplitude;
