import { createSlice } from "@reduxjs/toolkit";

export const surveySlice = createSlice({
  name: "survey",
  initialState: {
    rating: 1,
    message: "",
    interests: [],
    isSuccess: false,
    error: null,
  },
  reducers: {
    setRating: (state, action) => {
      state.rating = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setInterests: (state, action) => {
      state.interests = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setIsSuccess: (state, action) => {
      state.isSuccess = action.payload;
    },
    resetSurvey: (state) => {
      state.rating = 1;
      state.message = "";
      state.interests = [];
    },
  },
});

export const {
  setRating,
  setMessage,
  setInterests,
  resetSurvey,
  setError,
  setIsSuccess,
} = surveySlice.actions;

export default surveySlice.reducer;
