import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import PlayIcon from "../../assets/images/icons/playButtonAlt.png";
import colors from "../../constants/colors";
import Amplitude from "../../services/amplitudeHelper";
import { playAudio, stopAudio } from "../../slices/backgroundAudioSlice";

const VideoWrap = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const PlayButton = styled.img`
  width: 7.5%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

const VideoPlayer = styled.video`
  width: 84%;
  box-shadow: 0px 0px 15px ${colors.textShadowBlue};
  background-color: black;
`;

const FeatureVideo = ({ url, poster }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const dispatch = useDispatch();
  const videoRef = useRef(null);

  const startVideo = () => {
    Amplitude.clickWatchVideo("Company", "Learn More");
    setIsPlaying(true);
    dispatch(stopAudio());
    videoRef.current.play();
  };

  const videoEnded = () => {
    dispatch(playAudio());
    setIsPlaying(false);
  };

  const videoPlay = () => {
    dispatch(stopAudio());
  };

  const videoPaused = () => {
    dispatch(playAudio());
  };

  return (
    <VideoWrap>
      <VideoPlayer
        ref={videoRef}
        src={url}
        poster={poster}
        onPause={videoPaused}
        onEnded={videoEnded}
        onPlay={videoPlay}
        controls={isPlaying}
      />
      {!isPlaying && <PlayButton src={PlayIcon} onClick={startVideo} />}
    </VideoWrap>
  );
};

export default FeatureVideo;
