import { createSlice } from "@reduxjs/toolkit";

export const voiceoverSlice = createSlice({
  name: "voiceover",
  initialState: {
    isWelcomePlaying: false,
    isSurveyPlaying: false,
    isLearnMorePlaying: false,
    isSfxPlaying: false,
  },
  reducers: {
    setWelcomePlaying: (state, action) => {
      state.isWelcomePlaying = action.payload;
    },
    setSurveyPlaying: (state, action) => {
      state.isSurveyPlaying = action.payload;
    },
    setLearnMorePlaying: (state, action) => {
      state.isLearnMorePlaying = action.payload;
    },
    setSfxPlaying: (state, action) => {
      state.isSfxPlaying = action.payload;
    },
  },
});

export const {
  setWelcomePlaying,
  setSurveyPlaying,
  setLearnMorePlaying,
  setSfxPlaying,
} = voiceoverSlice.actions;

export default voiceoverSlice.reducer;
