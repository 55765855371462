import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import GalleryLayout from "../components/GalleryLayout";
import Gallery from "../assets/images/galleryPages/gallery3.jpg";
import PlaidFloat from "../assets/images/galleryLogos/plaidFloat.png";
import SolarisFloat from "../assets/images/galleryLogos/solarisbankFloat.png";
import HumaFloat from "../assets/images/galleryLogos/humaFloat.png";
import CalmFloat from "../assets/images/galleryLogos/calmFloat.png";
import Amplitude from "../services/amplitudeHelper";

const LinkBox = styled(motion.div)`
  width: calc(12.5vw);
  height: calc(12.5vw);
  background-color: ${(props) =>
    props.placeholder && "rgba(255, 255, 255, 0.5)"};
  position: absolute;
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FloatingLogo = styled(motion.img)`
  width: ${(props) => props.size};
`;

const DigitalZone = () => {
  const history = useHistory();

  const navigateTo = (url) => {
    Amplitude.clickCompanyLink(url.split("/")[0]);
    history.push(url);
  };

  useEffect(() => {
    Amplitude.visit("Digital Zone Gallery");
  }, []);

  return (
    <GalleryLayout backgroundImage={Gallery}>
      <LinkBox
        onClick={() => navigateTo("/digital-zone/plaid")}
        bottom="18%"
        left="11.5%"
        initial={{ y: 0 }}
        animate={{ y: 30 }}
        transition={{
          duration: 3,
          repeatType: "reverse",
          repeat: Infinity,
          repeatDelay: 2,
        }}
      >
        <FloatingLogo
          src={PlaidFloat}
          size="150%"
          alt="Plaid"
          initial={{ rotateY: 25 }}
          animate={{ rotateY: -25 }}
          transition={{ duration: 5, repeatType: "reverse", repeat: Infinity }}
        />
      </LinkBox>
      <LinkBox
        onClick={() => navigateTo("/digital-zone/solarisbank")}
        bottom="23%"
        left="27.5%"
        initial={{ y: 0 }}
        animate={{ y: 30 }}
        transition={{
          delay: 2,
          duration: 3,
          repeatType: "reverse",
          repeat: Infinity,
          repeatDelay: 2,
        }}
      >
        <FloatingLogo
          src={SolarisFloat}
          size="150%"
          alt="Solarisbank"
          initial={{ rotateY: 25 }}
          animate={{ rotateY: -25 }}
          transition={{ duration: 5, repeatType: "reverse", repeat: Infinity }}
        />
      </LinkBox>
      <LinkBox
        onClick={() => navigateTo("/digital-zone/huma")}
        bottom="23%"
        right="28%"
        initial={{ y: 0 }}
        animate={{ y: 30 }}
        transition={{
          delay: 3,
          duration: 3,
          repeatType: "reverse",
          repeat: Infinity,
          repeatDelay: 2,
        }}
      >
        <FloatingLogo
          src={HumaFloat}
          size="150%"
          alt="Huma"
          initial={{ rotateY: 25 }}
          animate={{ rotateY: -25 }}
          transition={{ duration: 5, repeatType: "reverse", repeat: Infinity }}
        />
      </LinkBox>
      <LinkBox
        onClick={() => navigateTo("/digital-zone/calm")}
        bottom="18%"
        right="12.5%"
        initial={{ y: 0 }}
        animate={{ y: 30 }}
        transition={{
          delay: 2,
          duration: 3,
          repeatType: "reverse",
          repeat: Infinity,
          repeatDelay: 2,
        }}
      >
        <FloatingLogo
          src={CalmFloat}
          size="150%"
          alt="Calm"
          initial={{ rotateY: 25 }}
          animate={{ rotateY: -25 }}
          transition={{ duration: 5, repeatType: "reverse", repeat: Infinity }}
        />
      </LinkBox>
    </GalleryLayout>
  );
};

export default DigitalZone;
