import { configureStore } from "@reduxjs/toolkit";
import modalReducer from "../slices/modalSlice";
import backgroundAudioReducer from "../slices/backgroundAudioSlice";
import surveyReducer from "../slices/surveySlice";
import appReducer from "../slices/appSlice";
import voiceoverReducer from "../slices/voiceoverSlice";
import layoutReducer from "../slices/layoutSlice";
import authReducer from "../slices/authSlice";

export default configureStore({
  reducer: {
    modal: modalReducer,
    backgroundAudio: backgroundAudioReducer,
    survey: surveyReducer,
    app: appReducer,
    voiceover: voiceoverReducer,
    layout: layoutReducer,
    auth: authReducer,
  },
});
