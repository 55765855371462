import axios from "axios";

export const submitSurvey = async (rating, interests, message) => {
  try {
    const url = `${process.env.REACT_APP_SERVER_PATH}/private/v1/surveys`;
    const response = await axios.post(url, {
      rating,
      interests,
      message,
    });

    if (response && response.status === 200) {
      return { isSuccessful: true };
    }
  } catch (error) {
    return { isSuccessful: false };
  }
};
