import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLogin: false,
    isPasswordReset: false,
    isPasswordResetRequest: false,
    isPrivacyPolicy: false,
    isAuthenticated: false,
    acceptedTerms: false,
    isSuccessfulSignUp: false,
    resetToken: "",
    passwordReset: {
      password: "",
      confirmPassword: "",
    },
    signUpUser: {
      name: "",
      email: "",
      password: "",
    },
    loginUser: {
      email: "",
      password: "",
    },
  },
  reducers: {
    setIsLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    setSignUpUser: (state, action) => {
      state.signUpUser = action.payload;
    },
    setLoginUser: (state, action) => {
      state.loginUser = action.payload;
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setAcceptedTerms: (state, action) => {
      state.acceptedTerms = action.payload;
    },
    setIsPasswordResetRequest: (state, action) => {
      state.isPasswordResetRequest = action.payload;
    },
    setIsPrivacyPolicy: (state, action) => {
      state.isPrivacyPolicy = action.payload;
    },
    setIsPasswordReset: (state, action) => {
      state.isLogin = true;
      state.resetToken = action.payload;
      state.isPasswordResetRequest = true;
      state.isPasswordReset = true;
    },
    clearIsPasswordReset: (state) => {
      state.isLogin = false;
      state.resetToken = "";
      state.isPasswordResetRequest = false;
      state.isPasswordReset = false;
    },
    resetSignUp: (state) => {
      state.signUpUser = {
        name: "",
        email: "",
        password: "",
      };
      state.acceptedTerms = false;
      state.isPrivacyPolicy = false;
      state.isSuccessfulSignUp = true;
    },
    setPasswordReset: (state, action) => {
      state.passwordReset = action.payload;
    },
    resetLogin: (state) => {
      state.loginUser = {
        email: "",
        password: "",
      };
    },
    setIsSuccessfulSignUp: (state) => {
      state.isSuccessfulSignUp = false;
    },
  },
});

export const {
  setIsLogin,
  setSignUpUser,
  setLoginUser,
  setIsAuthenticated,
  setAcceptedTerms,
  setIsPasswordResetRequest,
  setIsPrivacyPolicy,
  setIsPasswordReset,
  setIsSuccessfulSignUp,
  clearIsPasswordReset,
  resetSignUp,
  setPasswordReset,
  resetLogin,
} = authSlice.actions;

export default authSlice.reducer;
