import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

const BackgroundAudio = () => {
  const audioFile = useRef(null);
  const isPlaying = useSelector((state) => state.backgroundAudio.isPlaying);
  const audioURL = useSelector((state) => state?.app?.siteData?.audio_url);

  useEffect(() => {
    if (isPlaying) {
      audioFile.current.play();
    } else {
      audioFile.current.pause();
    }
  }, [isPlaying]);

  return (
    <>
      <audio ref={audioFile} src={audioURL} loop />
    </>
  );
};

export default BackgroundAudio;
