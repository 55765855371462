import { createSlice } from "@reduxjs/toolkit";

export const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    isMobile: false,
    isTablet: false,
    isDesktop: true,
  },
  reducers: {
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setIsTablet: (state, action) => {
      state.isTablet = action.payload;
    },
    setIsDesktop: (state, action) => {
      state.isDesktop = action.payload;
    },
  },
});

export const { setIsMobile, setIsTablet, setIsDesktop } = layoutSlice.actions;

export default layoutSlice.reducer;
