import React, { useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useHistory } from "react-router";
import GalleryLayout from "../components/GalleryLayout";
import Gallery from "../assets/images/galleryPages/gallery2.jpg";
import EpicFloat from "../assets/images/galleryLogos/epicFloat.png";
import DiscordFloat from "../assets/images/galleryLogos/discordFloat.png";
import Amplitude from "../services/amplitudeHelper";

const LinkBox = styled(motion.div)`
  width: calc(12.5vw);
  height: calc(12.5vw);
  background-color: ${(props) =>
    props.placeholder && "rgba(255, 255, 255, 0.5)"};
  position: absolute;
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FloatingLogo = styled(motion.img)`
  width: ${(props) => props.size};
`;

const Metaverse = () => {
  const history = useHistory();

  const navigateTo = (url) => {
    Amplitude.clickCompanyLink(url.split("/")[0]);
    history.push(url);
  };

  useEffect(() => {
    Amplitude.visit("Metaverse Gallery");
  }, []);

  return (
    <GalleryLayout backgroundImage={Gallery}>
      <LinkBox
        onClick={() => navigateTo("/metaverse/epic-games")}
        bottom="25%"
        left="26%"
        initial={{ y: 0 }}
        animate={{ y: 30 }}
        transition={{
          duration: 3,
          repeatType: "reverse",
          repeat: Infinity,
          repeatDelay: 2,
        }}
      >
        <FloatingLogo
          src={EpicFloat}
          size="150%"
          alt="Epic Games"
          initial={{ rotateY: 25 }}
          animate={{ rotateY: -25 }}
          transition={{ duration: 5, repeatType: "reverse", repeat: Infinity }}
        />
      </LinkBox>
      <LinkBox
        onClick={() => navigateTo("/metaverse/discord")}
        bottom="25%"
        right="27%"
        initial={{ y: 0 }}
        animate={{ y: 30 }}
        transition={{
          delay: 2,
          duration: 3,
          repeatType: "reverse",
          repeat: Infinity,
          repeatDelay: 3,
        }}
      >
        <FloatingLogo
          src={DiscordFloat}
          size="150%"
          alt="Epic Games"
          initial={{ rotateY: 25 }}
          animate={{ rotateY: -25 }}
          transition={{
            duration: 5,
            repeatType: "reverse",
            repeat: Infinity,
          }}
        />
      </LinkBox>
    </GalleryLayout>
  );
};

export default Metaverse;
