import React from "react";
import styled from "styled-components/macro";

const BGImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
`;

const BackgroundImage = ({ image }) => {
  return <BGImage src={image} alt="" />;
};

export default BackgroundImage;
