import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import GalleryLayout from "../components/GalleryLayout";
import Gallery from "../assets/images/galleryPages/gallery4.jpg";
import IllumioFloat from "../assets/images/galleryLogos/illumioFloat.png";
import DatabricksFloat from "../assets/images/galleryLogos/databricksFloat.png";
import NetskopeFloat from "../assets/images/galleryLogos/netskopeFloat.png";
import Amplitude from "../services/amplitudeHelper";

const LinkBox = styled(motion.div)`
  width: calc(12.5vw);
  height: calc(12.5vw);
  background-color: ${(props) =>
    props.placeholder && "rgba(255, 255, 255, 0.5)"};
  position: absolute;
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FloatingLogo = styled(motion.img)`
  width: ${(props) => props.size};
`;

const AICybersecurity = () => {
  const history = useHistory();

  const navigateTo = (url) => {
    Amplitude.clickCompanyLink(url.split("/")[0]);
    history.push(url);
  };

  useEffect(() => {
    Amplitude.visit("AI Cybersecurity Gallery");
  }, []);

  return (
    <GalleryLayout backgroundImage={Gallery}>
      <LinkBox
        onClick={() => navigateTo("/ai-cybersecurity/illumio")}
        bottom="25%"
        left="19%"
        initial={{ y: 0 }}
        animate={{ y: 30 }}
        transition={{
          duration: 3,
          repeatType: "reverse",
          repeat: Infinity,
          repeatDelay: 2,
        }}
      >
        <FloatingLogo
          src={IllumioFloat}
          size="150%"
          alt="Illumio"
          initial={{ rotateY: 25 }}
          animate={{ rotateY: -25 }}
          transition={{ duration: 5, repeatType: "reverse", repeat: Infinity }}
        />
      </LinkBox>
      <LinkBox
        onClick={() => navigateTo("/ai-cybersecurity/databricks")}
        bottom="25%"
        left="44%"
        initial={{ y: 0 }}
        animate={{ y: 30 }}
        transition={{
          delay: 2,
          duration: 3,
          repeatType: "reverse",
          repeat: Infinity,
          repeatDelay: 2,
        }}
      >
        <FloatingLogo
          src={DatabricksFloat}
          size="150%"
          alt="Databricks"
          initial={{ rotateY: 25 }}
          animate={{ rotateY: -25 }}
          transition={{ duration: 5, repeatType: "reverse", repeat: Infinity }}
        />
      </LinkBox>
      <LinkBox
        onClick={() => navigateTo("/ai-cybersecurity/netskope")}
        bottom="25%"
        right="21%"
        initial={{ y: 0 }}
        animate={{ y: 30 }}
        transition={{
          delay: 3,
          duration: 3,
          repeatType: "reverse",
          repeat: Infinity,
          repeatDelay: 2,
        }}
      >
        <FloatingLogo
          src={NetskopeFloat}
          size="150%"
          alt="Netskope"
          initial={{ rotateY: 25 }}
          animate={{ rotateY: -25 }}
          transition={{ duration: 5, repeatType: "reverse", repeat: Infinity }}
        />
      </LinkBox>
    </GalleryLayout>
  );
};

export default AICybersecurity;
