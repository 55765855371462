const sideTabs = [
  {
    id: 1,
    value: "video",
    label: "Feature Video",
  },
  {
    id: 2,
    value: "overview",
    label: "Overview",
  },
  {
    id: 3,
    value: "highlights",
    label: "Highlights",
  },
  {
    id: 4,
    value: "about",
    label: "About",
  },
  {
    id: 5,
    value: "company-website",
    label: "Company Website",
  },
  {
    id: 6,
    value: "video-playlist",
    label: "Video Playlist",
  },
];

export default sideTabs;
