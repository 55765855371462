import React from "react";
import styled from "styled-components/macro";
import colors from "../../constants/colors";
import Play from "../../assets/images/icons/playlistPlay.png";
import { useParams } from "react-router";
import Amplitude from "../../services/amplitudeHelper";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  width: 80%;
  flex: 1;
  overflow: scroll;
  border-radius: 15px;
  background-color: ${colors.highlightBackground};
  padding: 1% 2%;
  margin: 3% 1%;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(36, 36, 36, 0.2);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
  }
`;

const VideoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 2%;
`;

const Thumbnail = styled.img`
  width: 20%;
  margin-right: 3%;
`;

const VideoDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const VideoTitle = styled.div`
  color: ${colors.white};
  font-size: calc(1.5vw);
  font-family: "Calibri-Bold";
`;

const VideoPlayButton = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top: 2%;
  font-family: "Calibri-Bold";
  font-size: calc(1.2vw);
  color: ${colors.white};
`;

const PlayIcon = styled.img`
  width: 2vw;
  margin-right: 2%;
`;

const Divider = styled.hr`
  border-color: ${colors.divider};
`;

const CompanyPlaylist = ({ videos }) => {
  const { company } = useParams();

  const handleClickPlay = (url) => {
    Amplitude.clickPlaylistVideo(company, url);
  };

  return (
    <Wrapper>
      <Content>
        {videos.map((video, index) => {
          return (
            <div key={`${index}-${video.title}`}>
              <VideoWrapper>
                <Thumbnail src={video.thumbnail_url} alt={video.title} />
                <VideoDetails>
                  <VideoTitle>{video.title}</VideoTitle>
                  <VideoPlayButton
                    href={video.url}
                    onClick={() => handleClickPlay(video.url)}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <PlayIcon src={Play} alt="Play" />
                    PLAY
                  </VideoPlayButton>
                </VideoDetails>
              </VideoWrapper>
              {index !== videos.length - 1 && <Divider />}
            </div>
          );
        })}
      </Content>
    </Wrapper>
  );
};

export default CompanyPlaylist;
