import React from "react";
import styled from "styled-components/macro";
import colors from "../../constants/colors";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.img`
  width: 40%;
  margin-top: -15%;
  margin-bottom: -15%;
`;

const Title = styled.div`
  text-transform: uppercase;
  font-size: calc(1.8vw);
  margin: 3% 0;
  font-family: "Calibri-Bold";
  font-weight: 600;
  color: ${colors.white};
  letter-spacing: 0.2%;
  text-align: center;
`;

const Content = styled.div`
  width: 90%;
  overflow: auto;
  border-radius: 15px;
  background-color: ${colors.highlightBackground};
  padding: 2%;
  margin-bottom: 6%;
  white-space: pre-wrap;
  color: ${colors.white};
  font-family: "Calibri-Bold";
  font-size: calc(1.3vw);
  line-height: calc(2vw);
  margin: 0;

  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const CompanyAbout = ({ about, logo }) => {
  return (
    <Wrapper>
      <Logo src={logo} alt="Logo" />
      <Title>About</Title>
      <Content dangerouslySetInnerHTML={{ __html: about }} />
    </Wrapper>
  );
};

export default CompanyAbout;
