import { createSlice } from "@reduxjs/toolkit";

export const backgroundAudioSlice = createSlice({
  name: "backgroundAudio",
  initialState: {
    isPlaying: false,
    firstEntry: true,
    userMuted: false,
  },
  reducers: {
    playAudio: (state) => {
      if (!state.userMuted) {
        state.isPlaying = true;
      }
    },
    stopAudio: (state) => {
      state.isPlaying = false;
    },
    userPlayAudio: (state) => {
      state.isPlaying = true;
      state.userMuted = false;
    },
    userStopAudio: (state) => {
      state.isPlaying = false;
      state.userMuted = true;
    },
    setFirstEntry: (state) => {
      state.firstEntry = false;
    },
  },
});

export const {
  playAudio,
  stopAudio,
  setFirstEntry,
  userPlayAudio,
  userStopAudio,
} = backgroundAudioSlice.actions;

export default backgroundAudioSlice.reducer;
