import React from "react";
import BackgroundImage from "./BackgroundImage";
import SixteenByNine from "./SixteenByNineContainer";
import CompanyNavigation from "./CompanyNavigation";

const CompanyPageLayout = ({ children, backgroundImage }) => {
  return (
    <>
      <SixteenByNine bgImg={backgroundImage}>
        <BackgroundImage image={backgroundImage} />
        <CompanyNavigation />
        {children}
      </SixteenByNine>
    </>
  );
};

export default CompanyPageLayout;
