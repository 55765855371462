import React from "react";
import styled from "styled-components/macro";
import colors from "../../constants/colors";
import HighlightIcon from "../../assets/images/icons/highlightsIcon.png";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.img`
  width: 40%;
  margin-top: -15%;
  margin-bottom: -15%;
`;

const Title = styled.div`
  text-transform: uppercase;
  font-size: calc(1.8vw);
  margin: 3% 0;
  font-family: "Calibri-Bold";
  font-weight: 600;
  color: ${colors.white};
  letter-spacing: 0.2%;
  text-align: center;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: ${colors.highlightBackground};
  padding: 3% 2%;
  overflow: auto;
`;

const HighlightBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${colors.highlightBox};
  padding: 1.25%;
  border-radius: 15px;
  margin: 1% 0;
`;

const HighlightText = styled.div`
  font-size: calc(1.3vw);
  color: ${colors.white};
  font-family: "Calibri-Bold";
`;

const StyledIcon = styled.img`
  height: calc(2vw);
  width: calc(2vw);
  margin: 0 1%;
  margin-right: 2%;
`;

const CompanyHighlights = ({ highlights, logo }) => {
  return (
    <Wrapper>
      <Logo src={logo} alt="Logo" />
      <Title>Company Highlights</Title>
      <Content>
        {highlights.map((highlight, index) => {
          return (
            <HighlightBox key={`${index}-highlight`}>
              <StyledIcon src={HighlightIcon} alt="" />
              <HighlightText>{highlight}</HighlightText>
            </HighlightBox>
          );
        })}
      </Content>
    </Wrapper>
  );
};

export default CompanyHighlights;
