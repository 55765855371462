const leftColOptions = [
  {
    id: "1-L",
    label: "Space X",
    value: "space-x",
  },
  {
    id: "2-L",
    label: "Axiom",
    value: "axiom",
  },
  {
    id: "3-L",
    label: "Relativity",
    value: "relativity",
  },
  {
    id: "4-L",
    label: "Huma",
    value: "huma",
  },
  {
    id: "5-L",
    label: "Calm",
    value: "calm",
  },
  {
    id: "6-L",
    label: "Discord",
    value: "discord",
  },
];

const rightColOptions = [
  {
    id: "1-R",
    label: "EPIC Games",
    value: "epic-games",
  },
  {
    id: "2-R",
    label: "Netskope",
    value: "netskope",
  },
  {
    id: "3-R",
    label: "Illumio",
    value: "illumio",
  },
  {
    id: "4-R",
    label: "DataBricks",
    value: "databricks",
  },
  {
    id: "5-R",
    label: "Plaid",
    value: "plaid",
  },
  {
    id: "6-R",
    label: "Solarisbank",
    value: "solarisbank",
  },
];

export { leftColOptions, rightColOptions };
