import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import {
  resetSurvey,
  setError,
  setInterests,
  setIsSuccess,
  setRating,
} from "../../slices/surveySlice";
import { leftColOptions, rightColOptions } from "../../constants/surveyOptions";

import colors from "../../constants/colors";
import fontSize from "../../constants/fontSize";
import breakpoints from "../../constants/breakpoints";
import { submitSurvey } from "../../services/axios/survey";
import Amplitude from "../../services/amplitudeHelper";

const FormWrapper = styled.div`
  padding: 0.5rem;
  margin-top: 0.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const MessageWrap = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Message = styled.div`
  font-size: ${fontSize.xLarge};
  color: ${colors.white};
  font-weight: bold;
  max-width: 50%;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const FormControl = styled.div`
  margin: 0.5rem 0;
`;

const FormControlOption = styled.div`
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
`;

const StyledFormLabel = styled.label`
  display: flex;
  color: ${colors.white};
  font-size: ${fontSize.large};
`;

const LabelNumber = styled.span`
  margin-right: 1rem;
`;

const LabelText = styled.div`
  flex: 1;
`;

const SliderBox = styled.div`
  padding: 1rem 0;
  display: flex;
`;

const Slider = styled.input`
  flex-grow: 1;
  margin: 0 2rem;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 5px;
  outline: none;
  border-radius: 2.5px;
  opacity: 0.6;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  :hover {
    opacity: 0.8;

    ::-webkit-slider-thumb {
      background: ${colors.sliderHover};
    }

    ::-moz-range-thumb {
      background: ${colors.sliderHover};
    }
  }

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 25px;
    background: ${colors.midGrey};
    cursor: pointer;
  }

  ::-moz-range-thumb {
    width: 15px;
    height: 25px;
    background: ${colors.midGrey};
    cursor: pointer;
  }
`;

const SliderLabels = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SliderLabel = styled.div`
  font-size: ${fontSize.large};
  color: ${colors.white};
`;

const OptionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(1, 1fr);
`;

const LeftCol = styled.div`
  padding: 1rem;
  grid-row: 1 / 2;
  grid-column: 1 / 4;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media screen and (max-width: ${breakpoints.md}) {
    grid-column: 1 / 6;
  }
`;

const RightCol = styled.div`
  padding: 1rem;
  grid-row: 1 / 2;
  grid-column: 4 / 11;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media screen and (max-width: ${breakpoints.md}) {
    grid-column: 6 / 11;
  }
`;

const CheckboxWrap = styled.div`
  margin: 0.15rem 0;
  display: flex;
  align-items: center;
`;

const Checkbox = styled.input``;

const CheckboxLabel = styled.div`
  margin-left: 0.25rem;
  font-size: ${fontSize.large};
  color: ${colors.white};
`;

const TextAreaWrap = styled.div`
  display: flex;
  flex-direction: column;

  textarea {
    padding: 0.5rem;
    color: ${colors.white};
    margin: 1rem;
    border: 1px solid ${colors.white};
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    /* resize: none; */
    outline: none;
    user-select: auto;
    -webkit-user-select: auto;
  }
`;

const EmailUs = styled.div`
  color: ${colors.white};
  font-size: ${fontSize.large};
  margin: 0 1rem;
`;

const EmailLink = styled.a`
  color: ${colors.white};
  text-decoration: underline;

  :visited {
    color: ${colors.white};
    opacity: 0.8;
  }
`;

const StyledButton = styled.button`
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid ${colors.white};
  border-radius: 5px;
  padding: 0.5rem 2rem;
  max-width: 180px;
  color: ${colors.white};
  margin: 0 auto;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  margin-top: 0.25rem;

  :disabled {
    opacity: 0.5;
  }
`;

const ErrorBox = styled.div`
  font-size: ${fontSize.large};
  color: ${colors.white};
  margin-top: 1rem;
  text-align: center;
`;

const SurveyForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const messageFieldRef = useRef(null);
  const rating = useSelector((state) => state.survey.rating);
  const interests = useSelector((state) => state.survey.interests);
  const isSuccess = useSelector((state) => state.survey.isSuccess);
  const error = useSelector((state) => state.survey.error);

  const dispatch = useDispatch();

  const handleSlider = (e) => {
    dispatch(setRating(e.target.value));
  };

  const handleCheckbox = (e) => {
    if (interests.includes(e.target.value)) {
      const newInterests = interests.filter(
        (interest) => interest !== e.target.value
      );
      dispatch(setInterests(newInterests));
    } else {
      const newInterests = [...interests, e.target.value];
      dispatch(setInterests(newInterests));
    }
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setError(null));
    if (rating && interests.length > 0) {
      setIsSubmitting(true);
      Amplitude.submitSurvey(rating, interests, message);
      const response = await submitSurvey(rating, interests, message);

      if (response.isSuccessful) {
        dispatch(setIsSuccess(true));
        setIsSubmitting(false);
        dispatch(resetSurvey());
      } else {
        setIsSubmitting(false);
        dispatch(setError("Failed to submit survey, please try again."));
      }
    } else {
      dispatch(setError("Please complete question 1 & 2 to submit."));
    }
  };

  return (
    <FormWrapper>
      {isSuccess ? (
        <MessageWrap>
          <Message>
            Your response has been received. <br /> Thank you for completing the
            survey.
          </Message>
        </MessageWrap>
      ) : (
        <>
          <Form onSubmit={handleSubmit}>
            <FormControl>
              <StyledFormLabel>
                <LabelNumber>1.</LabelNumber>
                <LabelText>
                  How was your Virtual Hall Tour Experience?{" "}
                </LabelText>
              </StyledFormLabel>
              <SliderBox>
                <Slider
                  type="range"
                  min="1"
                  max="10"
                  step="1"
                  value={rating}
                  onChange={handleSlider}
                />
              </SliderBox>
              <SliderLabels>
                <SliderLabel>Below Expectation (1)</SliderLabel>
                <SliderLabel>Great (10)</SliderLabel>
              </SliderLabels>
            </FormControl>
            <FormControlOption>
              <StyledFormLabel>
                <LabelNumber>2.</LabelNumber>
                <LabelText>
                  Which assets interest you the most? (Please tick the
                  appropriate boxes)
                </LabelText>
              </StyledFormLabel>
              <OptionContainer>
                <LeftCol>
                  {leftColOptions.map((option) => {
                    return (
                      <CheckboxWrap key={option.id}>
                        <Checkbox
                          type="checkbox"
                          value={option.value}
                          onChange={handleCheckbox}
                          checked={interests.includes(option.value)}
                        />
                        <CheckboxLabel>{option.label}</CheckboxLabel>
                      </CheckboxWrap>
                    );
                  })}
                </LeftCol>
                <RightCol>
                  {rightColOptions.map((option) => {
                    return (
                      <CheckboxWrap key={option.id}>
                        <Checkbox
                          type="checkbox"
                          value={option.value}
                          onChange={handleCheckbox}
                          checked={interests.includes(option.value)}
                        />
                        <CheckboxLabel>{option.label}</CheckboxLabel>
                      </CheckboxWrap>
                    );
                  })}
                </RightCol>
              </OptionContainer>
            </FormControlOption>
            <FormControl>
              <StyledFormLabel>
                <LabelNumber>3.</LabelNumber>
                <LabelText>
                  For general enquiry, feedback or assistance, please mention
                  below (optional):
                </LabelText>
              </StyledFormLabel>
              <TextAreaWrap>
                <textarea
                  ref={messageFieldRef}
                  rows="2"
                  value={message}
                  onChange={handleMessage}
                  name="messageContent"
                  onFocus={() => {
                    messageFieldRef.current.scrollIntoView();
                  }}
                />
                <EmailUs>
                  You may also email us at{" "}
                  <EmailLink href="mailto:frontier@ericsenz.com">
                    frontier@ericsenz.com
                  </EmailLink>
                </EmailUs>
              </TextAreaWrap>
            </FormControl>
            <StyledButton type="submit" disabled={isSubmitting}>
              Submit
            </StyledButton>
            {error && <ErrorBox>{error}</ErrorBox>}
          </Form>
        </>
      )}
    </FormWrapper>
  );
};

export default SurveyForm;
