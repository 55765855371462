import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components/macro";
import FeatureVideo from "./FeatureVideo";
import CompanyOverview from "./CompanyOverview";
import CompanyHighlights from "./CompanyHighlights";
import CompanyAbout from "./CompanyAbout";
import CompanyWebsite from "./CompanyWebsite";
import CompanyPlaylist from "./CompanyPlaylist";
import companyData from "../../constants/companyData";

const Wrapper = styled.div`
  height: 90%;
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 3% 10% 8% 4%;
`;

const Tab = styled(motion.div)`
  width: 100%;
  height: 100%;
`;

const CompanyTabs = ({ activeTab, data }) => {
  const { company_datum } = data;
  const logo = companyData[data.shortname].logo;

  return (
    <Wrapper>
      {activeTab === "video" && (
        <AnimatePresence>
          <Tab
            key={1}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0 }}
          >
            <FeatureVideo
              url={company_datum.video_url}
              poster={company_datum.video_thumbnail_url}
            />
          </Tab>
        </AnimatePresence>
      )}
      {activeTab === "overview" && (
        <AnimatePresence>
          <Tab
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0 }}
          >
            <CompanyOverview
              logo={logo}
              whatTheyDo={company_datum.what_they_do}
              imageOne={company_datum.image_one_url}
              imageTwo={company_datum.image_two_url}
              founded={company_datum.founded}
              valuation={company_datum.valuation}
              quote={company_datum.quote}
            />
          </Tab>
        </AnimatePresence>
      )}
      {activeTab === "highlights" && (
        <Tab
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          exit={{ opacity: 0 }}
        >
          <CompanyHighlights
            logo={logo}
            highlights={company_datum.highlights}
          />
        </Tab>
      )}
      {activeTab === "about" && (
        <Tab
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          exit={{ opacity: 0 }}
        >
          <CompanyAbout logo={logo} about={company_datum.about} />
        </Tab>
      )}
      {activeTab === "company-website" && (
        <Tab
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          exit={{ opacity: 0 }}
        >
          <CompanyWebsite logo={logo} url={company_datum.website_url} />
        </Tab>
      )}
      {activeTab === "video-playlist" && (
        <Tab
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          exit={{ opacity: 0 }}
        >
          <CompanyPlaylist videos={data.playlist_videos} />
        </Tab>
      )}
    </Wrapper>
  );
};

export default CompanyTabs;
