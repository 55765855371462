import RelativityLogo from "../assets/images/logos/relativity.png";
import SpaceXLogo from "../assets/images/logos/spaceX.png";
import AxiomLogo from "../assets/images/logos/axiomSpace.png";
import EpicLogo from "../assets/images/logos/epicGames.png";
import DiscordLogo from "../assets/images/logos/discord.png";
import PlaidLogo from "../assets/images/logos/plaid.png";
import SolarisbankLogo from "../assets/images/logos/solarisBank.png";
import HumaLogo from "../assets/images/logos/huma.png";
import CalmLogo from "../assets/images/logos/calm.png";
import DBLogo from "../assets/images/logos/dataBricks.png";
import IllumioLogo from "../assets/images/logos/illumio.png";
import NetskopeLogo from "../assets/images/logos/netSkope.png";

const companyData = {
  relativity: { logo: RelativityLogo },
  "space-x": { logo: SpaceXLogo },
  "axiom-space": { logo: AxiomLogo },
  "epic-games": { logo: EpicLogo },
  discord: { logo: DiscordLogo },
  plaid: { logo: PlaidLogo },
  solarisbank: { logo: SolarisbankLogo },
  huma: { logo: HumaLogo },
  calm: { logo: CalmLogo },
  databricks: { logo: DBLogo },
  illumio: { logo: IllumioLogo },
  netskope: { logo: NetskopeLogo },
};

export default companyData;
