import axios from "axios";

export const getSiteData = async () => {
  try {
    const url = `${process.env.REACT_APP_SERVER_PATH}/public/v1/site_data`;
    const response = await axios.get(url);

    if (response?.status === 200) {
      return { isSuccess: true, data: response?.data };
    } else {
      return { isSuccess: false };
    }
  } catch (error) {
    return { isSuccess: false };
  }
};

export const getCompanyData = async (company) => {
  try {
    const url = `${process.env.REACT_APP_SERVER_PATH}/public/v1/companies/${company}`;
    const response = await axios.get(url);

    if (response?.status === 200) {
      return { isSuccess: true, data: response?.data };
    } else {
      return { isSuccess: false };
    }
  } catch (error) {
    return { isSuccess: false };
  }
};
