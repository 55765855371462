import React from "react";
import { useHistory } from "react-router";
import { motion } from "framer-motion";
import styled from "styled-components/macro";

import Home from "../assets/images/homeButton.png";
import GoBack from "../assets/images/backButton.png";
import { Link } from "react-router-dom";

const Navigation = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10%;
  z-index: 9999;
`;

const HomeButton = styled(motion.img)`
  position: absolute;
  bottom: 0;
  right: 19%;
  height: 60%;
  cursor: pointer;
`;

const GoBackButton = styled(motion.img)`
  position: absolute;
  bottom: 0;
  left: 28%;
  height: 60%;
  cursor: pointer;
`;

const TopNavigation = () => {
  const history = useHistory();

  return (
    <Navigation>
      <GoBackButton
        src={GoBack}
        onClick={() => history.goBack()}
        transition={{ type: "tween" }}
        whileHover={{ scale: 1.1 }}
      />
      <Link to="/">
        <HomeButton
          src={Home}
          transition={{ type: "tween" }}
          whileHover={{ scale: 1.1 }}
        />
      </Link>
    </Navigation>
  );
};

export default TopNavigation;
