import React, { useEffect, useState } from "react";
import { cacheImages, getAllImages } from "./cacheHelper";
import LandingBackground from "../assets/images/landingBg.png";
import Initializing from "../assets/images/initializing.png";
import styled from "styled-components/macro";
import SixteenByNineContainer from "../components/SixteenByNineContainer";
import BackgroundImage from "../components/BackgroundImage";
import colors from "../constants/colors";
import { useDispatch, useSelector } from "react-redux";
import { setHasClickedEnter, setSiteData } from "../slices/appSlice";
import { getSiteData } from "./axios/cms";
import { toast } from "react-toastify";

const InitializingText = styled.img`
  position: absolute;
  top: 8%;
  left: 50%;
  transform: translateX(-50%);
  width: 40%;
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translateX(-50%);
  font-size: calc(2%) !important;
`;

const DisclaimerText = styled.div`
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  font-size: calc(0.9vw);
  color: ${colors.grey};
  text-align: center;
  width: 50%;
  text-shadow: 0px 0px 15px rgba(0, 226, 255, 0.7);
`;

const DisclaimerTitle = styled.div`
  display: block;
  font-size: calc(1.1vw);
  font-weight: bold;
`;

const PreCache = ({ children }) => {
  const [isCached, setIsCached] = useState(false);
  const dispatch = useDispatch();
  const hasClickedEnter = useSelector((state) => state.app.hasClickedEnter);

  useEffect(() => {
    const fetchSiteData = async () => {
      const response = await getSiteData();

      if (response?.isSuccess) {
        setIsCached(true);
        dispatch(setSiteData(response?.data));
      } else {
        toast.error("Error loading content, please contact site owner.");
      }
    };
    const images = getAllImages();

    cacheImages(images)
      .then(() => {
        fetchSiteData();
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch]);

  return (
    <>
      {hasClickedEnter ? (
        <>{children}</>
      ) : (
        <SixteenByNineContainer hideMute>
          <BackgroundImage image={LandingBackground} />
          <InitializingText src={Initializing} alt="" />
          <ButtonWrap>
            <div
              className="animated-button"
              onClick={() => dispatch(setHasClickedEnter(true))}
              disabled={!isCached}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              {!isCached ? "Loading..." : "Click here to Begin"}
            </div>
          </ButtonWrap>
          <DisclaimerText>
            <DisclaimerTitle>DISCLAIMER</DisclaimerTitle>
            All assets’ footages / logos contained within do not belong to
            Ericsenz Capital Pte. Ltd. All rights belong to its rightful
            owner/owners. No copyright infringement.
          </DisclaimerText>
        </SixteenByNineContainer>
      )}
    </>
  );
};

export default PreCache;
